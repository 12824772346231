import XeroLink from 'components/XeroLink'
import React, { useState } from 'react'
import LogInfo from './LogInfo.js'
import styles from '../Main/styles'
import { DRF_TYPE, recurringStatus } from '../Main/constant.js'
import { denied } from '../Main/configuration.js'
import ExpireDialog from '../dialogs/ExpireTemplate.js'

export default function MainCtaFooter({
  form,
  formDisabled,
  disapproveButtonText,
  approveButtonText,
  files,
  nextStatus,
  handleConfirmationConfirm,
  setShowUnableToProcess,
  isSaveRequired,
  drfType,
  calculation,
  lockedForEdit,
}) {
  const [expireTemplateDialog, setExpireTemplateDialog] = useState(false)
  const canSubmit = (approving) => {
    if (['pending', 'aged_pending', ...denied].includes(form.status) || isSaveRequired()) {
      return true
    }

    if (calculation?.disableCta) {
      return false
    }

    if (recurringStatus.includes(form.nextStatus)) {
      const conditions = form.requestorId && (form.recurringDistribution?.dayRepeats || form.recurringDistribution.endDate) && files.length > 0

      return conditions
    }

    let baseConditions =
      form.account &&
      form.amount &&
      form.dateReceived &&
      form.xeroPayTo?.name &&
      form.requestorId &&
      (form.expeditedPaymentNeeded === 'no' || form.expeditedDateNeeded) &&
      files.length > 0 &&
      (drfType == DRF_TYPE.TEMPLATE ? form.recurringDistribution?.dayRepeats && form.recurringDistribution?.endDate : true)

    if (form.status === 'denied') {
      return baseConditions && form.description
    }
    if (['written', 'recurring_written'].includes(form.status)) {
      if (['pay_by_phone', 'pay_online'].includes(form.paymentType) && nextStatus === 'admin_wrapped_up') {
        baseConditions = baseConditions && form.paymentSubtype
      }
      return baseConditions && form.paymentType
    }
    if (approving) {
      if (['pay_by_phone', 'pay_online'].includes(form.paymentType)) {
        if (!form.instructions) {
          return false
        }

        if (nextStatus == 'admin_wrapped_up' && !form.paymentSubtype) {
          return false
        }
      }

      return baseConditions && form.paymentType && form.description
    } else {
      return baseConditions
    }
  }
  const showDisapproveBtn = () => {
    if (nextStatus === 'admin_wrapped_up' && ['check_in_house', 'cashier_check'].includes(form.paymentType)) {
      return true
    }
    return !denied.includes(form.status)
  }
  return (
    <>
      <div className={`${styles.submitButtons} ${styles.submitButtonsJustify}`}>
        {form?.xeroLink ? <XeroLink xeroUrl={form.xeroLink} description={'View Bill in Xero'} /> : <div></div>}

        {form.inSecondSigner && lockedForEdit ? (
          <div className={styles.secondSignerWarning}>A second signer is required for transactions over $25K</div>
        ) : (
          <>
            {form.status === 'recurring_approved' && (
              <section className={styles.submitButtons}>
                <button className={styles.button} id="expire" onClick={() => setExpireTemplateDialog(true)}>
                  <span>Expire Template Now</span>
                </button>
              </section>
            )}

            {form.status === 'recurring_expired' && (
              <section className={styles.submitButtons}>
                <button className={styles.button} id="expire" onClick={() => setExpireTemplateDialog(true)}>
                  <span>Copy Template for Re-Approval</span>
                </button>
              </section>
            )}

            {(!formDisabled || (formDisabled && ['admin_wrapped_up'].includes(nextStatus))) && (
              <section className={styles.submitButtons}>
                {showDisapproveBtn() && (
                  <button className={styles.buttonDanger} id="reject" onClick={() => setShowUnableToProcess(true)}>
                    <span>{disapproveButtonText}</span>
                  </button>
                )}

                {approveButtonText && (
                  <button disabled={!canSubmit(true)} className={styles.button} type="submit" onClick={(event) => handleConfirmationConfirm(event)}>
                    <span>{approveButtonText}</span>
                  </button>
                )}
              </section>
            )}
          </>
        )}
      </div>
      {form.events && form.events.length > 0 && (
        <LogInfo
          events={form.events}
          headers={[
            { key: 'createdAt', value: 'When', className: styles.logTableHeader },
            { key: 'createdBy', value: 'Who', className: styles.logTableHeader },
            { key: 'description', value: 'Action', className: styles.logTableHeader },
          ]}
        />
      )}

      {expireTemplateDialog && (
        <ExpireDialog setDialog={setExpireTemplateDialog} recurringId={form?.recurringDistribution?.id} status={form.status} />
      )}
    </>
  )
}
