import React from 'react'
import { Dialog } from 'components/Dialog'
import { ButtonText } from '../Main/confirmationText.js'
import { DeniedSection, PendingSection } from '../modals/ModalSection.js'

export default function Dialogs(props) {
  const {
    showConfirmationDialog,
    handleConfirmationCancel,
    handleConfirmationConfirm,
    showUnableToProcess,
    handleUnableProcessCancel,
    handleUnableProcessConfirm,
    moveToPending,
    setMoveToPending,
    clientSelect,
    setClientSelect,
    trustSelect,
    setTrustSelect,
    handleMoveToPending,
    moveToDenied,
    deniedReasonSelect,
    setDeniedReasonSelect,
    setMoveToDenied,
    handleMoveToDenied,
  } = props
  return (
    <>
      {showConfirmationDialog && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="OK"
          // headerText={confirmationDialogText}
          onCancel={handleConfirmationCancel}
          onConfirm={handleConfirmationConfirm}
        >
          {/* {confirmationDialogText} */}
        </Dialog>
      )}
      {showUnableToProcess && (
        <Dialog
          cancelButtonText={
            <ButtonText
              primary={'No'}
              secondary={
                <>
                  Move to <p>Denied</p>
                </>
              }
            />
          }
          confirmButtonText={
            <ButtonText
              primary={'Yes'}
              secondary={
                <>
                  Move to <p>Pending</p>
                </>
              }
            />
          }
          headerText={
            <div>
              Can we <i>possibly</i> get the information we need to process this request?
            </div>
          }
          onCancel={handleUnableProcessCancel}
          onConfirm={handleUnableProcessConfirm}
        />
      )}

      {moveToPending && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Move to Pending"
          bodyText={
            <PendingSection clientSelect={clientSelect} setClientSelect={setClientSelect} trustSelect={trustSelect} setTrustSelect={setTrustSelect} />
          }
          onCancel={() => {
            setMoveToPending(false)
          }}
          disabled={clientSelect?.length == 0 && trustSelect == null}
          onConfirm={handleMoveToPending}
        />
      )}
      {moveToDenied && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Move to Denied"
          bodyText={<DeniedSection deniedReasonSelect={deniedReasonSelect} setDeniedReasonSelect={setDeniedReasonSelect} showTitle />}
          onCancel={() => {
            setMoveToDenied(false)
          }}
          disabled={deniedReasonSelect?.length == 0}
          onConfirm={handleMoveToDenied}
        />
      )}
    </>
  )
}
