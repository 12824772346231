// @flow
import React from 'react'
import { connect } from 'react-redux'
import { httpGet } from 'lib/http'
import queryString from 'query-string'
import { setLoad, setUnload } from 'lib/notification/actions'
import { Template } from './Template'

const CHUNK_EVERY = 75

type Props = {
  dispatch: Function,
}

class AccountStatements extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      batches: null,
      statements: null,
      journalId: '',
      dateStart: '',
      dateEnd: '',
      withRecipients: true,
    }
  }

  handleChange = (event) => {
    const { name, value: rawValue } = event.currentTarget
      ? event.currentTarget
      : event
    var value
    switch (name) {
      case 'journalId':
        value = rawValue.value
        break
      case 'withRecipients':
        value = event.currentTarget.checked
        break
      default:
        value = rawValue
    }
    this.setState({ [name]: value })
  }

  canSubmit = () => {
    const { journalId, dateStart, dateEnd } = this.state
    return journalId && dateStart && dateEnd && dateStart <= dateEnd
  }

  fetchStatementBatches = () => {
    const { dateStart, dateEnd, journalId, withRecipients } =
      this.state
    const { dispatch } = this.props
    const query = queryString.stringify(
      {
        date_start: dateStart,
        date_end: dateEnd,
        journal_id: journalId,
        with_recipients: withRecipients,
        chunk_every: CHUNK_EVERY,
      },
      { arrayFormat: 'bracket' },
    )

    dispatch(setLoad())
    httpGet(`/admin/account-statement-batches?${query}`)
      .then(({ batches }) => {
        this.setState({ batches })
      })
      .then(() => {
        dispatch(setUnload())
      })
  }

  fetchStatements = (batch) => {
    const { dateStart, dateEnd, journalId, withRecipients } =
      this.state
    const { dispatch } = this.props
    const query = queryString.stringify(
      {
        date_start: dateStart,
        date_end: dateEnd,
        journal_id: journalId,
        with_recipients: withRecipients,
        chunk_every: CHUNK_EVERY,
        batch: batch,
      },
      { arrayFormat: 'bracket' },
    )

    dispatch(setLoad())
    httpGet(`/admin/account-statements?${query}`)
      .then(({ accountStatements }) => {
        const key = parseInt(batch) + 1
        localStorage.setItem(0, JSON.stringify(accountStatements))
        window.open(`/account-statements-view?id=${key}`)
      })
      .then(() => {
        dispatch(setUnload())
      })
      .catch(() => {})
  }

  render() {
    return (
      <Template
        dateEnd={this.state.dateEnd}
        dateStart={this.state.dateStart}
        journalId={this.state.journalId}
        onChange={this.handleChange}
        canSubmit={this.canSubmit()}
        onSubmit={this.fetchStatementBatches}
        onBatchClick={this.fetchStatements}
        batches={this.state.batches}
        statements={this.state.statements}
        withRecipients={this.state.withRecipients}
      />
    )
  }
}

export default connect()(AccountStatements)
