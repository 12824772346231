import SimpleFileFolder from 'components/SimpleFileFolder'
import XeroContactSearch from 'components/XeroContactSearch'
import XeroLink from 'components/XeroLink'
import { httpGet } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import query_string from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CurrencyFormatInput from '../../../../../../components/CurrencyFormatInput/index.js'
import SectionHeader from '../../Header/SectionHeader.js'
import styles from '../styles'
import { DRF_TYPE } from '../constant.js'
import { useSelector } from 'react-redux'
import { KEY as NOTIFICATION_KEY } from 'lib/notification/constants'

export default function WriteFormTwo({
  form,
  updateForm,
  handleContactSelect,
  clearContactSelect,
  xeroLink,
  nextStatus,
  formDisabled,
  handleRecurringUpdate,
  lockedForEdit,
  invoiceXeroLink,
  files,
  onDeleteClick,
  onUploadClick,
  drfType,
  accounts,
  calculation,
}) {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state[NOTIFICATION_KEY].loading)

  const handleXeroContactRefresh = (event) => {
    event.preventDefault()
    if (!form.xeroPayTo.id) {
      dispatch(setBanner('No Contact Found...', 'danger'))
      return
    }
    dispatch(setLoad())
    const queryString = query_string.stringify({
      journalName: form.account.journalName,
      contactId: form.xeroPayTo.id,
    })

    httpGet(`/admin/xero-contact-refresh?${queryString}`)
      .then(({ contact }) => {
        handleContactSelect(contact)
      })
      .catch((err) => {
        // display banner
        dispatch(setBanner(err, 'danger'))
      })
      .finally(() => {
        dispatch(setUnload())
      })
  }

  const newContactUrl = () => {
    return 'http://go.xero.com/app/' + form.shortCode + '/contacts/add'
  }

  const contactExists = (id) => form.accountContacts?.find((contact) => contact.id == id)

  const getMailUsingId = (id) => {
    const contact = contactExists(id)
    if (!contact) return null
    const subject =
      'We%20have%20received%20your%20request&body=We%20have%20received%20your%20distribution%20request%20and%20will%20process%20it%20within%203%20to%205%20business%20days.'
    return `mailto:${contact.email}?subject=${subject}`
  }

  const hasInvoiceId = invoiceXeroLink != null

  return (
    <div className={styles.sectionTwo}>
      {drfType == DRF_TYPE.TEMPLATE && (
        <div className={styles.cardMarginBot}>
          {/* {formDisabled && !['recurring_approved'].includes(form.status) &&
           <div className={styles.disabledMask}></div>} */}
          <SectionHeader title="Uploads" />

          <section className={styles.sectionNoMargin}>
            <section className={styles.subSection}>
              <div className={styles.fileUpload}>
                <label className={styles.label}>Request Documents</label>
                <div className={styles.fileUploadComponent}>
                  <SimpleFileFolder onUploadClick={onUploadClick} files={files} onDeleteClick={onDeleteClick} />
                </div>
              </div>
            </section>
          </section>
        </div>
      )}
      <div className={styles.cardMarginBot}>
        {((formDisabled && hasInvoiceId) || lockedForEdit || ['recurring_approved', 'recurring_expired'].includes(form.status)) && (
          <div className={styles.disabledMask}></div>
        )}
        <SectionHeader title="Write Info" />

        <section className={styles.sectionNoMargin}>
          <section className={styles.subSection}>
            <div className={styles.formItemHalf}>
              <label className={styles.label} htmlFor="payTo">
                Who is making the request?
              </label>
              <div>
                <select
                  disabled={drfType == DRF_TYPE.SCHEDULED}
                  className={`${styles.select} ${styles.requestor}`}
                  name="requestorId"
                  value={form.requestorId || ''}
                  onChange={updateForm}
                  required
                >
                  <option value="">Choose Contact</option>
                  {accounts?.map((contact, index) => {
                    return (
                      <option value={contact.id} key={`${contact.id}-${index}`}>
                        {contact.name} - {contact.role}
                      </option>
                    )
                  })}
                </select>
                {form.requestorId != null && contactExists(form.requestorId) != null && (
                  <div className={styles.requestorLink}>
                    <a href={getMailUsingId(form.requestorId)}>Send requestor email</a>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className={styles.subSection}>
            <div className={styles.formItemMono}>
              <label className={`${styles.label} ${styles.requestAmountLabel}`} htmlFor="amount">
                Request Amount {!loading && calculation.message != null && <div className={calculation.styles}>{calculation.message}</div>}
              </label>
              <CurrencyFormatInput
                disabled={invoiceXeroLink != null || drfType == DRF_TYPE.SCHEDULED}
                className={`${styles.input} ${styles.amountInput} ${calculation.styles}`}
                name="amount"
                setValue={updateForm}
                placeholder="$0.00"
                value={form.amount}
                required
              />
            </div>
          </section>
          <section className={styles.subSection}>
            <div className={styles.formItemHalf}>
              <label className={styles.label} htmlFor="payTo">
                Pay To
              </label>
              <div className={styles.searchComponent}>
                <XeroContactSearch
                  disabled={form.account === '' || invoiceXeroLink != null || drfType == DRF_TYPE.SCHEDULED}
                  journalName={form.account && form.account.journalName}
                  onContactSelect={handleContactSelect}
                  valueInsideInput={form.xeroPayTo.name ?? null}
                  clearContactSelect={clearContactSelect}
                  inputType="textarea"
                />
              </div>
              <div className={styles.payTo}>
                <div className={styles.xeroLinkItem}>
                  {form.xeroPayTo && form.xeroPayTo.name ? (
                    <div>
                      <div className={styles.xeroItem}>
                        <XeroLink xeroUrl={xeroLink ?? form.xeroLink} />
                        <div className={styles.payToAddress}>
                          <span className={styles.subText}>{form.xeroPayTo.street}</span>
                          {form.xeroPayTo.city || form.xeroPayTo.state ? (
                            <span className={styles.subText}>
                              {form.xeroPayTo.city}, {form.xeroPayTo.state} {form.xeroPayTo.zip}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <a
                      href={newContactUrl()}
                      // className={styles.xeroRefresh}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Create new contact in Xero
                    </a>
                  )}
                  <div className={styles.xeroRefresh} onClick={handleXeroContactRefresh}>
                    Refresh Address from Xero
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={styles.subSection}>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="reference">
                Reference field of transaction
              </label>
              <textarea
                disabled={invoiceXeroLink != null || drfType == DRF_TYPE.SCHEDULED}
                className={styles.textareaSmall}
                type="text"
                name="reference"
                value={form.reference || ''}
                onChange={updateForm}
                rows="2"
                required
              />
            </div>
          </section>
          <section className={styles.subSection}>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="description">
                Transaction Description - seen on client statements
              </label>
              <textarea
                disabled={invoiceXeroLink != null || drfType == DRF_TYPE.SCHEDULED}
                className={styles.textareaSmall}
                type="text"
                name="description"
                value={form.description || ''}
                onChange={updateForm}
                rows="2"
                required
              />
            </div>
          </section>
          <section className={styles.subSection}>
            <div className={styles.formItemHalf}>
              <label className={styles.label} htmlFor="paymentType">
                Payment Type
              </label>
              <div>
                <select
                  className={styles.select}
                  name="paymentType"
                  value={form.paymentType || ''}
                  onChange={updateForm}
                  disabled={!['recurring_written', 'written'].includes(nextStatus) || drfType == DRF_TYPE.SCHEDULED}
                  required
                >
                  <option value="">Choose Type</option>
                  <option value="wire">Wire</option>
                  <option value="ach">ACH</option>
                  <option value="cashier_check" disabled={drfType == DRF_TYPE.TEMPLATE}>
                    Cashier Check
                  </option>
                  <option value="check_in_house">Print - In House</option>
                  {form.amount < 10000 && (
                    <>
                      <option value="check_external">Check - External</option>
                      <option value="pay_by_phone">Pay by Phone</option>
                      <option value="pay_online">Pay Online</option>{' '}
                    </>
                  )}
                </select>
              </div>
            </div>
            {['pay_by_phone', 'pay_online'].includes(form.paymentType) && (
              <div className={styles.formItem}>
                <label className={styles.label} htmlFor="instructions">
                  Instructions
                </label>
                <div>
                  <textarea
                    className={styles.textarea}
                    disabled={drfType == DRF_TYPE.SCHEDULED}
                    name="instructions"
                    placeholder="Please include details about how to complete this payment, i.e., phone number, website address, etc."
                    value={form.instructions || ''}
                    onChange={updateForm}
                    rows="4"
                    required
                  />
                </div>
              </div>
            )}
            {['pay_by_phone', 'pay_online'].includes(form.paymentType) && (
              <div className={styles.formItemHalf}>
                <label className={styles.label} htmlFor="paymentSubtype">
                  Payment Method
                </label>
                <div>
                  <select className={styles.select} name="paymentSubtype" value={form.paymentSubtype || ''} onChange={updateForm} required>
                    <option value="">Choose Type</option>
                    <option value="e_check">E-Check</option>
                    <option value="gt_credit_card">GT Credit Card</option>
                  </select>
                </div>
                {form.paymentSubtype === 'gt_credit_card' && (
                  <span className={styles.caption}>Please make a note with last four digits of the credit card you used.</span>
                )}
              </div>
            )}

            {/* checkboxes */}
            {['cashier_check', 'check_in_house'].includes(form.paymentType) && (
              <>
                <section className={styles.subSection}>
                  <div className={styles.radioButtonGroup}>
                    <label className={styles.label} htmlFor="expeditedMailing">
                      Paid mailing needed?
                    </label>
                    <div className={styles.buttons}>
                      <input
                        type="radio"
                        name="expeditedMailing"
                        value="yes"
                        disabled={!['recurring_written', 'written'].includes(nextStatus)}
                        checked={form.expeditedMailing === 'yes'}
                        onChange={updateForm}
                      />
                      <label htmlFor="yes">Yes</label>
                      <input
                        type="radio"
                        disabled={!['recurring_written', 'written'].includes(nextStatus)}
                        name="expeditedMailing"
                        value="no"
                        checked={form.expeditedMailing === 'no'}
                        onChange={updateForm}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </section>
                {form.expeditedMailing === 'yes' && (
                  <section className={styles.subSection}>
                    <div className={styles.radioButtonGroup}>
                      <label className={styles.label} htmlFor="mailingFeeAssessed">
                        Charge mailing fee to client?
                      </label>
                      <div className={styles.buttons}>
                        <input
                          type="radio"
                          name="mailingFeeAssessed"
                          value="yes"
                          disabled={!['recurring_written', 'written'].includes(nextStatus)}
                          checked={form.mailingFeeAssessed === 'yes'}
                          onChange={updateForm}
                        />
                        <label htmlFor="yes">Yes</label>
                        <input
                          type="radio"
                          name="mailingFeeAssessed"
                          disabled={!['recurring_written', 'written'].includes(nextStatus)}
                          value="no"
                          checked={form.mailingFeeAssessed === 'no'}
                          onChange={updateForm}
                        />
                        <label htmlFor="no">No</label>
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
          </section>
        </section>
      </div>
      {form.templateId == null && drfType !== DRF_TYPE.TEMPLATE && (
        <div className={styles.cardMarginBot}>
          {formDisabled && <div className={styles.disabledMask}></div>}
          <SectionHeader title="Recurring" />
          <section className={styles.sectionNoMargin}>
            <div className={styles.radioButtonGroup}>
              <label className={styles.label} htmlFor="requested">
                Recurring Payment Request
              </label>
              <div className={styles.buttons}>
                <input
                  type="radio"
                  name="requested"
                  value={'true'}
                  checked={`${form?.recurringDistribution?.requested}` == 'true'}
                  onChange={handleRecurringUpdate}
                />
                <label htmlFor="requested">Yes</label>
                <input
                  type="radio"
                  name="requested"
                  value={'false'}
                  checked={`${form?.recurringDistribution?.requested}` == 'false' || form?.recurringDistribution?.requested == null}
                  onChange={handleRecurringUpdate}
                />
                <label htmlFor="no">No</label>
              </div>
            </div>
            {['cashier_check'].includes(form.paymentType) && `${form?.recurringDistribution?.requested}` == 'true' && (
              <span className={styles.recurringPaymentWarning}>Recurring payments may not be paid by cashier check.</span>
            )}
          </section>
        </div>
      )}
    </div>
  )
}
