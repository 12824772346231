// @flow
import React from 'react'
import { AccountStatement } from 'components/AccountStatement'
import { DateRangeSelection } from 'components/DateRangeSelection'
import FormButtons from 'components/FormButtons'
import formatters from 'lib/formatters'
import styles from './styles'

type Props = {
  canSubmit: Function,
  onSubmit: Function,
  onChange: Function,
  dateStart: string,
  dateEnd: string,
  dobSSN: boolean,
  statement: Object,
}

const wrapSubmit = (onSubmit) => (event) => {
  onSubmit()
  event.preventDefault()
}

export const Template = ({
  canSubmit,
  onSubmit,
  statement,
  dateStart,
  dateEnd,
  dobSSN,
  onChange,
}: Props) => {
  var addedHeadersMap = new Map()

  if (statement && statement.beneficiarySSN) {
    addedHeadersMap = new Map(
      [['DOB',formatters.date(statement.beneficiaryDOB)],
        ['SSN',formatters.ssn(statement.beneficiarySSN)]]
        )
  }

  return statement ? (
    <AccountStatement addedHeadersMap={addedHeadersMap} {...statement} />
  ) : (
    <section>
      <div className={styles.localHeader}>
        <div className={styles.localHeaderInner}>
          <h5>Generate Account Statement</h5>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <h4>Specify the date range.</h4>
        </div>
        <div className={styles.warning}>
          GPT balances were forwarded from Abacus on 01/01/2017. Any statements
          with start dates before that date will indicate a $0 starting balance.
        </div>
        <form className={styles.form} onSubmit={wrapSubmit(onSubmit)}>
          <div className={styles.section}>
            <div className={styles.halfWidth}>
              <DateRangeSelection
                dateStart={dateStart}
                dateEnd={dateEnd}
                onSubmit={onSubmit}
                onChange={onChange}
              />
              <div className={styles.formItem}>
                <input
                  className={styles.checkbox}
                  id="dobSSN"
                  name="dobSSN"
                  type="checkbox"
                  checked={dobSSN}
                  onChange={onChange}
                />
                <label className={styles.label}
                  htmlFor="dobSSN">
                  Include Date of Birth and SSN
                </label>
              </div>
              <div className={styles.formItem}>
                <label className={styles.label}
                  htmlFor="emailAddresses">
                  Optional email addresses to send statement to
                  <br/>
                  (separated by commas):
                </label>
                <input
                  className={styles.input}
                  id="emailAddresses"
                  name="emailAddresses"
                  type="string"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <FormButtons disabled={!canSubmit()} submitLabel="Submit" />
        </form>
      </div>
    </section>
  )
}
