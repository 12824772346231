// @flow
import React from 'react'
import { connect } from 'react-redux'
import { TrustInput } from 'components/TrustInput'
import { DateRangeSelection } from 'components/DateRangeSelection'
import { Table, Row, Cell } from 'components/Table'
import { S3FilePreview } from 'components/S3FilePreview'
import { Link } from 'react-router-dom'
import { httpGet, httpUrl } from 'lib/http'
import { setLoad, setUnload } from 'lib/notification/actions'
import streamSaver from 'streamsaver'
import formatters from 'lib/formatters'
import styles from './DepositReport.css'

type Props = {
  dispatch: Function,
}

type State = {
  trust: '',
  dateStart: '',
  dateEnd: '',
  deposits: ?Object,
}

class DepositReport extends React.Component<Props, State> {
  props: Props

  constructor(props) {
    super(props)
    this.state = { dateStart: '', dateEnd: '', trust: '', deposits: '' }
  }

  fetchReport = () => {
    const { dispatch } = this.props
    const { trust, dateStart, dateEnd } = this.state
    dispatch(setLoad())
    const query_string = this.queryString(trust, dateStart, dateEnd)

    httpGet(`/admin/reporting/deposit-report${query_string}`)
      .then((deposits) => {
        this.setState({ deposits: deposits })
      })
      .then(() => {
        dispatch(setUnload())
      })
  }

  queryString = (trust, dateStart, dateEnd) => {
    return (
      `?journal_id=${trust}&` +
      `date_start=${dateStart}&` +
      `date_end=${dateEnd}`
    )
  }

  exportReport = (event) => {
    event.preventDefault()
    if (!this.canSubmit()) {
      console.log('cannot submit')
      return
    }

    const { dispatch } = this.props
    const { dateStart, dateEnd, trust } = this.state
    const sid = localStorage.getItem('sid')
    const base_url = httpUrl('/admin/reporting/deposit-report-export')
    const qs = this.queryString(trust, dateStart, dateEnd)
    const url = `${base_url}${qs}`

    dispatch(setLoad())

    fetch(url, {
      headers: {
        Authorization: `Bearer ${sid}`,
      },
    }).then((response) => {
      dispatch(setUnload())
      const fileStream = streamSaver.createWriteStream(`${trust}_deposits.csv`)
      response.body.pipeTo(fileStream)
    })
  }

  onChange = (event) => {
    const { name, value } = event.currentTarget
    this.setState({ [name]: value })
  }

  canSubmit = () => {
    const { trust, dateStart, dateEnd } = this.state
    return trust && dateStart && dateEnd && dateStart <= dateEnd
  }

  renderFileLink = (key) => {
    if (key) {
      return <S3FilePreview icon file_key={key} />
    } else {
      return <div></div>
    }
  }

  renderXeroLink = (url) => (
    <Link to={url} target="_blank">
      Xero
    </Link>
  )

  renderAccountLink = (number, index) => (
      <Link to={`/accounts/${number}`} key={index} target="_blank">
        {number}
      </Link>
  )

  renderDepositRow = (d, idx) => (
    <Row key={`custom${idx}`}>
      <Cell value={formatters.date(d.deposit_date)} />
      <Cell value={formatters.date(d.entry_date)} />
      <Cell value={formatters.money(d.amount)} />
      <Cell value={formatters.legibleDepositType(d.type)} />
      <Cell value={this.renderAccountLink(d.number, idx)} />
      <Cell value={d.fee_schedule} />
      <Cell value={formatters.percentage(d.fee_rate)} />
      <Cell value={formatters.money(d.enrollment_fee)} />
      <Cell value={formatters.money(d.fee_amount)} />
      <Cell value={formatters.money(d.total_balance)} />
      <Cell value={d.refund ? 'Y' : 'N'} />
      <Cell value={d.transfer ? 'Y' : 'N'} />
      <Cell value={this.renderFileLink(d.image_key)} />
      <Cell value={this.renderXeroLink(d.xero_link)} />
    </Row>
  )

  renderFooterRow = (total) => (
    <Row key={'footer'}>
      <Cell className={styles.highlightCell} value={'Total'} />
      <Cell value={' '} />
      <Cell value={' '} />
      <Cell value={' '} />
      <Cell value={' '} />
      <Cell value={' '} />
      <Cell value={' '} />
      <Cell value={' '} />
      <Cell className={styles.highlightCell} value={formatters.money(total)} />
      <Cell value={' '} />
      <Cell value={' '} />
      <Cell value={' '} />
      <Cell value={' '} />
    </Row>
  )

  renderDepositsTable = (deposits) => {
    let rows = deposits.map(this.renderDepositRow)

    const feeTotal = deposits.reduce(
      (acc, current) => acc + current.fee_amount,
      0,
    )
    rows.push(this.renderFooterRow(feeTotal))

    return (
      <div className={styles.depositsTable}>
        <Table
          title="Deposits Report"
          headers={[
            {value: 'Deposit Date'},
            {value: 'Entry Date'},
            {value: 'Amount'},
            {value: 'Type'},
            {value: 'Account'},
            {value: 'Fee Schedule'},
            {value: 'Fee Rate'},
            {value: 'Enrollment Fee'},
            {value: 'Fee Amount'},
            {value: 'Balance'},
            {value: 'Refund'},
            {value: 'Transfer'},
            {value: 'Image'},
            {value: 'Xero'}
          ]}
          tableSize="infinite"
          tableType="read"
        >
          {rows}
        </Table>
      </div>
    )
  }

  render() {
    const { trust, dateStart, dateEnd, deposits } = this.state

    return (
      <div>
        <section>
          <div className={styles.localHeader}>
            <div className={styles.localHeaderInner}>
              <h5>Deposits Report</h5>
            </div>
          </div>
          <div className={styles.contentContainer}>
            <form className={styles.form} onSubmit={this.onSubmit}>
              <div className={styles.section}>
                <div className={styles.halfWidth}>
                  <TrustInput
                    isClearable={false}
                    onChange={({ value: { value: selectedTrust } }) =>
                      this.setState({ trust: selectedTrust })
                    }
                    value={trust}
                  />
                  <DateRangeSelection
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    onChange={this.onChange}
                  />
                  <div className={styles.export}>
                    <button onClick={this.exportReport}>Export CSV</button>
                  </div>
                </div>
              </div>
            </form>
            {deposits && this.renderDepositsTable(deposits)}
          </div>
        </section>
      </div>
    )
  }

  componentDidUpdate(
    prevProps,
    { dateStart: prevStartDate, dateEnd: prevEndDate, trust: prevTrust },
  ) {
    if (
      this.state.dateStart != prevStartDate ||
      this.state.dateEnd != prevEndDate ||
      this.state.trust != prevTrust
    ) {
      if (this.canSubmit()) {
        this.fetchReport()
      }
    }
  }
}

export default connect()(DepositReport)
