// @flow
import React from 'react'
import { AccountExtrasRef, ContactRef, ContactListRef, GPTAccountDocsRef, GrantorRef, SSIRef, RemaindermenRef } from 'components/References'

type Props = {
  dispatch: Function,
  onSSNReset: Function,
  ticket: Object,
}

const FullAccountDetails = ({ dispatch, onSSNReset, ticket }: Props) => {
  const {
    ables,
    account_number,
    attachments,
    attorneys,
    beneficiary,
    co_grantor,
    consults,
    grantor,
    grantor_relationship,
    joinder_agreement_content_type,
    joinder_agreement_key,
    primary_grantor_doc_content_type,
    primary_grantor_doc_key,
    referrer,
    remaindermen,
    remaindermen_notes,
    representative,
    secondary_grantor_doc_content_type,
    secondary_grantor_doc_key,
  } = ticket
  let co_grantor_ref, joinder_agreement, primary_grantor_doc, representative_ref, secondary_grantor_doc
  if (co_grantor) {
    co_grantor_ref = (
      <GrantorRef
        contact={co_grantor}
        dispatch={dispatch}
        edit={true}
        full={true}
        onSSNReset={onSSNReset}
        relationship={grantor_relationship}
        account_number={account_number}
      />
    )
  }
  if (representative) {
    representative_ref = (
      <ContactRef contact={representative} dispatch={dispatch} edit={true} full={true} onSSNReset={onSSNReset} title="Representative" />
    )
  }
  if (joinder_agreement_content_type && joinder_agreement_key) {
    joinder_agreement = {
      content_type: joinder_agreement_content_type,
      key: joinder_agreement_key,
    }
  }
  if (primary_grantor_doc_content_type && primary_grantor_doc_key) {
    primary_grantor_doc = {
      content_type: primary_grantor_doc_content_type,
      key: primary_grantor_doc_key,
    }
  }
  if (secondary_grantor_doc_content_type && secondary_grantor_doc_key) {
    secondary_grantor_doc = {
      content_type: secondary_grantor_doc_content_type,
      key: secondary_grantor_doc_key,
    }
  }
  return (
    <div>
      <ContactRef contact={beneficiary} dispatch={dispatch} edit={true} full={true} onSSNReset={onSSNReset} title="Beneficiary" />
      <GrantorRef
        contact={grantor}
        dispatch={dispatch}
        edit={true}
        full={true}
        onSSNReset={onSSNReset}
        relationship={grantor_relationship}
        account_number={account_number}
      />
      {co_grantor_ref}
      <ContactRef contact={referrer} dispatch={dispatch} edit={true} full={true} onSSNReset={onSSNReset} title="Referrer" />
      {representative_ref}
      <ContactListRef contacts={attorneys} title="Attorneys" />
      <ContactListRef contacts={ables} title="Ables" />
      <ContactListRef contacts={consults} title="Consults" />
      <RemaindermenRef contacts={remaindermen} notes={remaindermen_notes} title="Remaindermen Contacts" />
      <GPTAccountDocsRef
        grantor_relationship={grantor_relationship}
        joinder_agreement={joinder_agreement}
        primary_grantor_doc={primary_grantor_doc}
        secondary_grantor_doc={secondary_grantor_doc}
        attachments={attachments}
      />
      <SSIRef
        hasSSI={ticket.has_ssi}
        hasSSIFood={ticket.has_ssi_food}
        hasSSIShelter={ticket.has_ssi_shelter}
        ssiAmount={ticket.ssi_amount}
        ssiApprovalContentType={ticket.ssi_approval_content_type}
        ssiApprovalData={ticket.ssi_approval_data}
        {...ticket}
      />
      <AccountExtrasRef
        hasHeader
        hasCustomFeeSchedule={ticket.has_custom_fee_schedule}
        hasNonCashAssets={ticket.has_non_cash_assets}
        isBurialPrepaid={ticket.is_burial_prepaid}
        isRestricted={ticket.is_restricted}
        {...ticket}
      />
    </div>
  )
}

export default FullAccountDetails
