import SendIcon from '@mui/icons-material/Send'
import { Cell, Row } from 'components/Table'
import formatters from 'lib/formatters'
import { httpPost } from 'lib/http'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { map } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { deniedRequestRadioOptions } from '../Main/constant'
import { LockUnlockIcon } from '../permissions'
import styles from './styles.css'
import IconDrf from 'styles/img/icon-standard-drf.png'
import IconScheduledDrf from 'styles/img/icon-scheduled-drf.png'
import IconRecurring from 'styles/img/icon-recurring-template.png'

const ICON_TYPE = {
  standard: IconDrf,
  template: IconRecurring,
  scheduled: IconScheduledDrf,
}

export default function RenderDistributionRow({ dist, tableStatus, nextStatus }) {
  const dispatch = useDispatch()

  const scheduledClick = (id) => {
    dispatch(setLoad())
    httpPost(`/admin/recurring_distributions/${id}?action=run`)
      .then(() => {
        dispatch(setBanner('Run success'))
      })
      .catch(() => {
        dispatch(setBanner('Ran unsuccessfully', 'danger'))
      })
      .then(() => {
        dispatch(unsetModal())
        dispatch(setUnload())
      })
  }
  const {
    id,
    account,
    requestType,
    amount,
    lastUpdatedDate,
    lastUpdatedUser,
    expeditedDateNeeded,
    insertedAt,
    paymentType,
    pendingDistribution,
    status,
    xeroPayTo,
    nextPaymentDate,
    owner,
    deniedDistribution,
    lockedForEdit,
    datePaid,
    recurringDistribution,
    type,
  } = dist
  const handleLink = () => {
    const searchParams = new URLSearchParams({ redirection_back: true })
    return `/distributions/${id}?${searchParams.toString()}`
  }

  let cells = []
  const runNowColumn = nextStatus == 'scheduled' && {
    value: 'Run now',
    key: 'run-now',
  }
  const ownerColumn = { value: owner?.name, key: 'owner' }
  const beneficiaryWithAccNumber = {
    key: 'beneficiary',
    value: `${account.number} - ${account.beneficiary}`,
  }
  const drfTypeColumn = {
    value: <div>{type && <img src={ICON_TYPE[type]} className={styles.logo} alt="DRF" />}</div>,
    key: 'type',
  }
  const frequencyColumn = {
    value: <div className="capitalize">{recurringDistribution?.period}</div>,
    key: 'period',
  }
  const lockUnlockColumn = {
    value: <LockUnlockIcon lockedForEdit={lockedForEdit} />,
    className: styles.cellNoPadding,
  }
  const paymentTypeColumn = {
    value: formatters.legiblePaymentType(paymentType),
    key: 'paymentType',
  }
  const nextPaymentDateColumn = { key: 'nextPaymentDate', value: formatters.date(nextPaymentDate) }
  const endDateColumn = {
    value: formatters.date(recurringDistribution?.endDate),
    key: 'endDate',
  }

  const lastUpdatedDateColumn = { key: 'lastUpdatedDate', value: formatters.fileTimestamp(lastUpdatedDate) }

  if (['main', 'paid'].includes(tableStatus)) {
    cells = [
      { value: formatters.dateTimeDiff(insertedAt, 'days'), key: 'daysIn' },
      {
        key: 'expeditedDateNeeded',
        value: expeditedDateNeeded ? <SendIcon sx={{ color: '#8E3132' }} /> : ' ',
      },
      beneficiaryWithAccNumber,
      { key: 'payToName', value: xeroPayTo.name },
      { key: 'amount', value: formatters.money(amount) },
      { key: 'requestType', value: formatters.toTitleCase(requestType) },
      lastUpdatedDateColumn,
      { key: 'lastUpdatedUser', value: lastUpdatedUser },
      { key: 'id', value: id },
    ]
    if (tableStatus === 'main') {
      cells.splice(1, 0, drfTypeColumn)
    }

    if (nextStatus === 'admin_wrapped_up') {
      cells = map(cells, function (a) {
        return a.key === 'requestType' ? ownerColumn : a
      })
    } else if (['signer_reviewed', 'admin_reviewed', 'approved_not_paid', 'paid_today'].includes(nextStatus)) {
      cells.splice(6, 0, ownerColumn)
    }

    if (status != 'received') {
      cells.push(paymentTypeColumn)
    }
    if (status === 'received' || nextStatus === 'written') {
      cells = cells.filter((cell) => !['amount', 'payToName'].includes(cell.key))
    }

    // paid
    if (tableStatus == 'paid') {
      // replace last updated with last paid
      cells = cells.map((cell) => {
        if (cell.key === 'lastUpdatedDate') {
          return {
            key: 'datePaid',
            value: formatters.date(datePaid),
          }
        }
        return cell
      })
      cells.splice(5, 0, ownerColumn)
      cells.splice(1, 0, drfTypeColumn)
    }
  } else if (tableStatus == 'recurring') {
    let recurring = [
      { value: formatters.dateTimeDiff(insertedAt, 'days'), key: 'daysIn' },
      {
        key: 'expeditedDateNeeded',
        value: expeditedDateNeeded ? <SendIcon sx={{ color: '#8E3132' }} /> : ' ',
      },
      beneficiaryWithAccNumber,
      { key: 'payToName', value: xeroPayTo.name },
      { key: 'amount', value: formatters.money(amount) },
      { key: 'requestType', value: formatters.toTitleCase(requestType) },
      lastUpdatedDateColumn,
      { key: 'lastUpdatedUser', value: lastUpdatedUser },
      frequencyColumn,
      endDateColumn,
      { key: 'id', value: id },
    ]
    recurring = recurring.filter((cell) => cell.key !== 'expeditedDateNeeded')

    let removeColumnId = []
    switch (nextStatus) {
      case 'recurring_written':
        removeColumnId = ['period']
        recurring = recurring.filter((cell) => !removeColumnId.includes(cell.key))
        break
      case 'recurring_reviewed':
      case 'recurring_approved':
        removeColumnId = ['daysIn', 'endDate']
        recurring = recurring.filter((cell) => !removeColumnId.includes(cell.key))
        break
      case 'scheduled':
        removeColumnId = ['insertedAt', 'lastUpdatedDate', 'daysIn', 'lastUpdatedUser']
        recurring = recurring.filter((cell) => !removeColumnId.includes(cell.key))
        if (['development', 'sandbox'].includes(process.env.NODE_ENV)) {
          recurring = [runNowColumn, ...recurring]
        }
        recurring.splice(6, 0, nextPaymentDateColumn)
        break
      default:
      //
    }

    if (status != 'received') {
      if (nextStatus == 'scheduled') {
        recurring.splice(6, 0, paymentTypeColumn)
      } else {
        recurring.splice(7, 0, paymentTypeColumn)
      }
    } else {
      recurring = recurring.filter((cell) => !['amount', 'payToName'].includes(cell.key))
    }

    cells = recurring
  } else if (tableStatus === 'pending') {
    cells = [
      {
        value: pendingDistribution?.dateEnteredPending != null && `${formatters.dateTimeDiff(pendingDistribution?.dateEnteredPending, 'days')}`,
        key: 'daysPending',
      }, // days pending
      drfTypeColumn,
      beneficiaryWithAccNumber,
      { value: pendingDistribution?.reason, key: 'reason' }, // reason
      ownerColumn, // owner
      {
        key: 'dateEnteredPending',
        value: formatters.date(pendingDistribution?.dateEnteredPending),
      },
      lastUpdatedDateColumn,
      { value: xeroPayTo.name, key: 'payToName' }, //Pay To
      { value: formatters.money(amount), key: 'amount' }, //amount
      { value: formatters.dateTimeDiff(insertedAt, 'days'), key: 'insertedAt' }, //days in queue
      { value: id, key: id }, //id
    ]
  } else if (tableStatus === 'denied') {
    let deniedReason = null
    if (deniedDistribution?.reason?.length > 1) {
      deniedReason = 'Multiple'
    } else if (deniedDistribution?.reason?.length == 1) {
      deniedReason = deniedRequestRadioOptions.find((req) => req.id == deniedDistribution?.reason?.[0])?.label
    }

    cells = [
      drfTypeColumn,
      beneficiaryWithAccNumber,
      {
        value: deniedReason,
        key: 'reason',
      }, // reason
      ownerColumn, // owner
      lastUpdatedDateColumn,
      // { value: '----', key: 'dateDenied' }, //days in queue
      { value: xeroPayTo.name, key: 'payToName' }, //Pay To
      { value: formatters.money(amount), key: 'amount' }, //amount
      { value: formatters.dateTimeDiff(insertedAt, 'days'), key: 'insertedAt' }, //days in queue
      { value: id, key: 'id' }, //id
    ]
  }

  cells.push(lockUnlockColumn)

  return (
    <Row key={`row-${id}`} link={handleLink()}>
      {cells?.map((cell, index) => {
        const onClick = cell.key == 'run-now' ? () => scheduledClick(id) : null
        return <Cell key={`${id}-${index}-cell`} value={cell.value} onClick={onClick} className={cell?.className} />
      })}
    </Row>
  )
}
