// @flow
import React from 'react'

import formatters from 'lib/formatters'
import type { Contact } from 'lib/types'
import Row from './Row'
import styles from './styles'

type Props = {
  contact: Contact,
  errors?: Object,
  relationship?: string,
}

class ContactFormRef extends React.Component<Props> {
  static defaultProps = {
    errors: {},
    relationship: null,
  }

  props: Props

  _addInfo() {
    const { contact, errors, relationship } = this.props

    let renderRelationship = null
    if (relationship) {
      const relationshipOptions = {
        coGuardian: 'Co-Guardian',
        proGuardian: 'Professional guardian',
        famGuardian: 'Family guardian',
        poa: 'Power of attorney',
        self: 'Self',
        selfRep: 'Self with representative',
        parent: 'Parent',
        grandparent: 'Grandparent',
        court: 'Court',
      }
      renderRelationship = (
        <div className={styles.relationshipInfo}>
          <Row
            label="Beneficiary type"
            val={relationshipOptions[relationship]}
          />
        </div>
      )
    }

    return (
      <div className={styles.formRefContent}>
        <div className={styles.formRefSection}>
          <Row
            label="Name"
            val={contact.name}
            error={errors.name}
            bold={true}
          />
          <Row
            label="Age"
            val={formatters.age(formatters.date(contact.dob))}
            mono={true}
          />
          <Row
            label="DOB"
            val={formatters.date(contact.dob)}
            mono={true}
            error={errors.dob}
          />
          <Row
            label="Photo ID?"
            val={contact.photo_id_id || contact.has_photo_id ? 'Yes' : 'No'}
            error={errors.photo_id_id}
          />
          <Row
            label="SSN Last 4"
            val={contact.ssn_verifier ? contact.ssn_verifier : '-'}
            error={errors.ssn}
          />
        </div>
        <div className={styles.formRefSection}>
          <Row
            label="Cell"
            val={
              contact.phone_numbers &&
              formatters.phone(contact.phone_numbers.cell_phone)
            }
            mono={true}
            error={errors.phone_numbers}
          />
          <Row
            label="Day"
            val={
              contact.phone_numbers &&
              formatters.phone(contact.phone_numbers.day_phone)
            }
            mono={true}
            error={errors.phone_numbers}
          />
          <Row
            label="Evening"
            val={
              contact.phone_numbers &&
              formatters.phone(contact.phone_numbers.evening_phone)
            }
            mono={true}
            error={errors.phone_numbers}
          />
          <Row
            label="Fax"
            val={
              contact.phone_numbers &&
              formatters.phone(contact.phone_numbers.fax)
            }
            mono={true}
            error={errors.phone_numbers}
          />
          <Row label="Email" val={contact.email} error={errors.email} />
        </div>
        <div className={styles.formRefSection}>
          <Row
            label="Street 1"
            val={contact.street_1}
            error={errors.street_1}
          />
          <Row label="Street 2" val={contact.street_2} />
          <Row label="City" val={contact.city} error={errors.city} />
          <Row label="State" val={contact.state} error={errors.state} />
          <Row label="Zip" val={contact.zip} error={errors.zip} />
        </div>
        {renderRelationship}
      </div>
    )
  }

  renderGlobalErrors = () => {
    const { _error } = this.props.errors

    if (!_error) {
      return null
    }

    const errors = Array.isArray(_error) ? _error : [_error]
    const headerText = 'Please resolve the following errors:'

    return (
      <div className={styles.globalErrors}>
        <h4>{headerText}</h4>
        <ul>{errors.map((error) => <li key={error}>{error}</li>)}</ul>
      </div>
    )
  }

  render() {
    if (!this.props.contact) return null

    return (
      <section className="marginBottom">
        {this.renderGlobalErrors()}
        {this._addInfo()}
      </section>
    )
  }
}

export default ContactFormRef
