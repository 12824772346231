// @flow
import React from 'react'
import { DateRangeSelection } from 'components/DateRangeSelection'
import FormButtons from 'components/FormButtons'
import { TrustInput } from 'components/TrustInput'
import { Table, Row, Cell } from 'components/Table'
import styles from './styles'

type Props = {
  dateEnd: string,
  dateStart: string,
  journalId: string,
  onChange: Function,
  onSubmit: Function,
  canSubmit: boolean,
  batches: Object[],
  withRecipients: boolean,
  onBatchClick: Function,
}

const wrapSubmit = (onSubmit) => (event) => {
  onSubmit()
  event.preventDefault()
}

const renderRow = (key, value, fn) => {
  const stmt_link = <a onClick={() => fn(key)}>{value}</a>

  const batch_num = parseInt(key) + 1

  return (
    <Row key={`batch-${key}`}>
      <Cell value={batch_num} />
      <Cell value={stmt_link} />
    </Row>
  )
}

const renderBatchRows = (batches, onBatchClick) => {
  var rows = []

  Object.entries(batches).forEach(([key, value]) => {
    rows.push(renderRow(key, value, onBatchClick))
  })

  return rows
}

const renderBatchesTable = (batches, onBatchClick) => {
  if (batches) {
    return (
      <div className={styles.table}>
        <Table
          title="Batches"
          headers={['Batch', 'First Accounts']}
          tableSize="large"
          tableType="read"
        >
          {renderBatchRows(batches, onBatchClick)}
        </Table>
      </div>
    )
  } else {
    return <div></div>
  }
}

export const Template = ({
  journalId,
  onChange,
  onSubmit,
  canSubmit,
  batches,
  dateStart,
  dateEnd,
  withRecipients,
  onBatchClick,
}: Props) => {
  return (
    <div>
      <section>
        <div className={styles.localHeader}>
          <div className={styles.localHeaderInner}>
            <h5>Generate Account Statements</h5>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <h4>Specify the date range.</h4>
          </div>
          <div className={styles.warning}>
            GPT balances were forwarded from Abacus on 01/01/2017. Any
            statements with start dates before that date will indicate a $0
            starting balance.
          </div>
          <form className={styles.form} onSubmit={wrapSubmit(onSubmit)}>
            <div className={styles.section}>
              <div className={styles.halfWidth}>
                <TrustInput
                  value={journalId}
                  multi={false}
                  onChange={onChange}
                />
                <DateRangeSelection
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  onSubmit={onSubmit}
                  onChange={onChange}
                />
                <div className={styles.formItem}>
                  <input
                    className={styles.checkbox}
                    id="withRecipients"
                    name="withRecipients"
                    type="checkbox"
                    checked={withRecipients}
                    onChange={onChange}
                  />
                  <label className={styles.label} htmlFor="withRecipients">
                    Include recipient addresses for mailing
                  </label>
                </div>
                {batches && renderBatchesTable(batches, onBatchClick)}
              </div>
            </div>
            <FormButtons disabled={!canSubmit} submitLabel="Submit" />
          </form>
        </div>
      </section>
    </div>
  )
}
