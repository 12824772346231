// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'components/References/Row'
import IconSwitch from 'components/IconSwitch/IconSwitch'
import styles from './styles'
import formatters from 'lib/formatters'
import { has } from 'lodash'

type Props = {
  ageAtOpen: number | null,
  hasCustomFeeSchedule: any,
  hasNonCashAssets: boolean,
  isBurialPrepaid: boolean,
  isRestricted: boolean,
  hasHeader?: string | null,
  potentialClientId?: number | null,
  trustName: string | null,
  productDescription: string | null,
  hasBudget: boolean,
  hasPublicGuardian: boolean,
  hasDonationClient: boolean,
  hasSpecialTaxSituation: boolean,
  spendingPlan: number | null,
  floor: number | null,
  hasParticipatingOrg: boolean,
  hasLien: boolean,
  hasTeams: boolean,
  hasPCard: boolean
}

const AccountExtrasRef = (props: Props) => {
  const {
    ageAtOpen,
    hasCustomFeeSchedule,
    hasNonCashAssets,
    isBurialPrepaid,
    isRestricted,
    hasHeader,
    potentialClientId,
    trustName,
    productDescription,
    hasBudget,
    hasPublicGuardian,
    hasDonationClient,
    hasSpecialTaxSituation,
    spendingPlan,
    floor,
    hasParticipatingOrg,
    hasLien,
    hasTeams,
    hasPCard
  } = props

  let cardStyle, header
  if (hasHeader) {
    cardStyle = styles.cardMarginBot
    header = (
      <div className={styles.titleSection}>
        <h5>Account Information</h5>
      </div>
    )
  }

  let clientIntakeRow
  if (potentialClientId) {
    const link =
      <Link
        to={`/potential_clients/edit/${potentialClientId}#distibutions`}
        target="_blank"
      >
        See client intake form
      </Link>

    clientIntakeRow = (
      <Row
        singleLine
        label="Distributions"
        val={link}
      />
    )
  }

  let trustNameRow
  if (trustName) {
    trustNameRow = (
      <Row
        singleLine
        label="Trust Name"
        val={trustName}
      />
    )
  }

  return (
    <div className={cardStyle}>
      {header}
      <div className={styles.accountExtrasSection}>
        <Row
          singleLine
          label="Age at account opening"
          val={ageAtOpen}
        />
        <Row
          singleLine
          label='Product'
          val={productDescription}
        />
        {trustNameRow}
        {hasCustomFeeSchedule && (
          <Row
            singleLine
            label="Custom Fee Schedule"
            val={<IconSwitch flag={hasCustomFeeSchedule} />}
          />
        )}
        {isBurialPrepaid && (
          <Row
            singleLine
            label="Prepaid Burial"
            val={<IconSwitch flag={isBurialPrepaid} />}
          />
        )}
        {isRestricted && (
          <Row
            singleLine
            label="Restricted"
            val={<IconSwitch flag={isRestricted} />}
          />
        )}
        {hasNonCashAssets && (
          <Row
            singleLine
            label="Non-Cash Assets"
            val={<IconSwitch flag={hasNonCashAssets} />}
          />
        )}
        {hasBudget && (
          <Row
            singleLine
            label="Budget"
            val={<IconSwitch flag={hasBudget} />}
          />
        )}
        {hasPublicGuardian && (
          <Row
            singleLine
            label="Public Guardian"
            val={<IconSwitch flag={hasPublicGuardian} />}
          />
        )}
        {hasDonationClient && (
          <Row
            singleLine
            label="Donation Client"
            val={<IconSwitch flag={hasDonationClient} />}
          />
        )}
        {hasSpecialTaxSituation && (
          <Row
            singleLine
            label="Special Tax Situation"
            val={<IconSwitch flag={hasSpecialTaxSituation} />}
          />
        )}
        {hasParticipatingOrg && (
          <Row
            singleLine
            label="Participating Organization"
            val={<IconSwitch flag={hasParticipatingOrg} />}
          />
        )}
        {hasLien && (
          <Row
            singleLine
            label="Lien"
            val={<IconSwitch flag={hasLien} />}
          />
        )}
        {hasTeams && (
          <Row
            singleLine
            label="TEAMS"
            val={<IconSwitch flag={hasTeams} />}
          />
        )}
        {hasPCard && (
          <Row
            singleLine
            label="P-Card"
            val={<IconSwitch flag={hasPCard} />}
          />
        )}
        {spendingPlan && (
          <Row
            singleLine
            label="Spending Plan (monthly)"
            val={formatters.money(spendingPlan)}
          />
        )}
        {floor && (
          <Row
            singleLine
            label="Floor"
            val={formatters.money(floor)}
          />
        )}
        {clientIntakeRow}
      </div>
    </div>
  )
}

export default AccountExtrasRef
