// @flow
import React from 'react'
import { httpDelete } from 'lib/http'
import { Table, Row, Cell } from 'components/Table'
import formatters from 'lib/formatters'
import { S3FilePreview } from 'components/S3FilePreview'
import AttachmentForm from './AttachmentForm'
import { unsetModal, setModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { Link } from 'react-router-dom'
import type { File, LineItem, Note } from 'lib/types'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import { Notes } from 'components/Notes'
import Header from './Header/Header.js'
import styles from './styles'

type Props = {
  attachments: [File],
  attachFile: Function,
  balance: number,
  beneficiaryName: string,
  lineItems: [LineItem],
  notes: [Note],
  number: string,
  open_date: string,
  subaccount_type: string,
  subnumber: string,
  dispatch: Function,
  openNewLineItemModal: Function,
  onSuccess: Function,
}

const openAttachmentModal = (number, subnumber, dispatch, onSuccess) => {
  const modalForm = (
    <AttachmentForm
      number={number}
      subnumber={subnumber}
      onSuccess={onSuccess}
    />
  )
  dispatch(setModal(modalForm))
}

const deleteFile = (dispatch, onSuccess, number, subnumber, fileId) => {
  return (
    <section>
      <div className="section">
        <h4>Are you sure you want to delete this file?</h4>
      </div>
      <div className={styles.buttons}>
        <button
          className={styles.removeBtn}
          onClick={() =>
            handleDelete(dispatch, onSuccess, number, subnumber, fileId)
          }
        >
          Delete File
        </button>
        <span className={styles.spacer} />
        <button
          className={styles.cancelBtn}
          onClick={() => dispatch(unsetModal())}
        >
          Cancel
        </button>
      </div>
    </section>
  )
}

const handleDelete = (dispatch, onSuccess, number, subnumber, fileId) => {
  dispatch(setLoad())
  const url = `/admin/accounts/${number}/subaccounts/${subnumber}/attachments/${fileId}`
  return httpDelete(url)
    .then(() => {
      dispatch(onSuccess)
      dispatch(setBanner('Document successfully deleted'))
    })
    .catch(() => {
      dispatch(setBanner('File deletion failed', 'danger'))
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
}

const renderLineItemRow = (item, idx) => (
  <Row key={`line-item-${idx}`}>
    <Cell value={formatters.date(item.effective_date)} />
    <Cell value={formatters.money(item.amount)} mono={true} />
    <Cell value={formatters.money(item.balance)} mono={true} />
    <Cell value={item.memo} />
  </Row>
)

const showAttachments = ({
  attachments,
  number,
  subnumber,
  dispatch,
  onSuccess,
}: Props) => {
  if (!Array.isArray(attachments)) return null
  let docs
  if (attachments.length) {
    docs = attachments.map((attachment, idx) => {
      const { file_id } = attachment
      const deleteHandler = () => {
        dispatch(
          setModal(deleteFile(dispatch, onSuccess, number, subnumber, file_id)),
        )
      }
      return (
        <div className={styles.otherDocsRow} key={idx}>
          {showLink(attachment)}
          <div className={styles.remove}>
            <a title="Delete File" onClick={deleteHandler} />
          </div>
        </div>
      )
    })
  } else {
    docs = <div className={styles.noDocResults}>No documents</div>
  }
  return (
    <section className={styles.cardMarginBot}>
      <div className={styles.docsWrapper}>{docs}</div>
    </section>
  )
}

const showLink = (file) => <S3FilePreview file_key={file.key} label={file.name} />

const renderBackLink = (number) => {
  const accountUrl = `/accounts/${number}`
  return <Link to={accountUrl}>Back to {number}</Link>
}

export const Template = (props: Props) => {
  const {
    beneficiaryName,
    lineItems,
    notes,
    balance,
    number,
    open_date,
    subaccount_type,
    subnumber,
    dispatch,
    openNewLineItemModal,
    onSuccess,
  } = props

  const tableTitle = 'Transactions'

  const addTransactionButton = (
    <Link className={styles.transactionButton} onClick={openNewLineItemModal}>
      Add Transaction
    </Link>
  )

  const generateStatementButton = (
    <Link
      className={styles.statementButton}
      to={`/accounts/${number}/subaccounts/${subnumber}/statement`}
    >
      Generate Statement
    </Link>
  )

  const renderedLineItems = lineItems ? lineItems.map(renderLineItemRow) : []

  const headers = [
    { value: 'Date' },
    { value: 'Change in Value', type: 'mono' },
    { value: 'Balance', type: 'mono' },
    { value: 'Memo' },
  ]

  return (
    <div>
      <Breadcrumbs crumbs={[renderBackLink(number)]} />
      <section className={styles.contentContainer}>
        <section className={styles.twoThird}>
          <Header
            balance={balance}
            beneficiary_name={beneficiaryName}
            number={number}
            subaccount_type={subaccount_type}
            subnumber={subnumber}
          />
          <div className={styles.table}>
            <Table
              title={tableTitle}
              headers={headers}
              tableSize="large"
              tableType="read"
            >
              {renderedLineItems}
            </Table>
          </div>
        </section>
        <div className={styles.oneThird}>
          <div className={styles.actions}>
            {addTransactionButton}
            {generateStatementButton}
          </div>
          {open_date && (
            <div className={styles.cardMarginBot}>
              <h5 className={styles.sectionTitle}>Account Information</h5>
              <div className={styles.sectionNoMargin}>
                <div className={styles.singleLine}>
                  <div className={styles.label}>Open Date</div>
                  <div className={styles.value}>
                    {formatters.date(open_date)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <Notes
            accountNumber={number}
            dispatch={dispatch}
            notes={notes}
            subnumber={subnumber}
            type="subaccount"
          />
          <section data-active={true} className={styles.collapseRef}>
            <div className={styles.titleSection}>
              <div className={styles.title}>
                <h5>Attachments</h5>
              </div>
              <span
                className={styles.leaveNoteIcon}
                onClick={() =>
                  openAttachmentModal(number, subnumber, dispatch, onSuccess)
                }
                title="Attach File"
              />
            </div>
            {showAttachments(props)}
          </section>
        </div>
      </section>
    </div>
  )
}
