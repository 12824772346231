// @flow
import React from 'react'
import Error from 'components/Error'
import { connect } from 'react-redux'
import { httpGet } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { Template } from './Template'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  params: Object,
}

class Statement extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      error: false,
      statement: null,
      dateStart: '',
      dateEnd: '',
      dobSSN: false,
      emailAddresses: '',
    }
  }

  handleChange = (event) => {
    const { name, value: rawValue } = event.currentTarget
      ? event.currentTarget
      : event
    var value
    switch (name) {
      case 'dobSSN':
        value = event.currentTarget.checked
        break
      default:
        value = rawValue
    }
    this.setState({ [name]: value })
  }

  canSubmit = () => {
    const { dateStart, dateEnd } = this.state

    return (
      dateStart != '' &&
      dateEnd != '' &&
      dateStart <= dateEnd
    )
  }

  fetchStatement = () => {
    const {
      dispatch,
      location: {
        params: { number },
      },
    } = this.props
    const { dateStart, dateEnd, dobSSN, emailAddresses } = this.state
    const query =
      `date_start=${dateStart}` +
      `&date_end=${dateEnd}` +
      `&dob_ssn=${dobSSN}` +
      `&email_addresses=${emailAddresses}`
    dispatch(setLoad())
    httpGet(`/admin/accounts/${number}/statement?${query}`)
      .then((data) => {
        if (data.number) {
          this.setState({ error: false, statement: data })
        } else if (data.msg.includes('Invalid')) {
          dispatch(setBanner(data.msg, 'danger'))
        } else {
          dispatch(setBanner(data.msg))
        }
      })
      .catch((error) => {
        if (error.code === 404) {
          this.setState({ error: true })
        } else {
          throw error
        }
      })
      .then(() => {
        dispatch(setUnload())
      })
  }

  render() {
    if (this.state.error) {
      return (
        <Error
          title="404: Account Not Found"
          message="The account you are looking for does not exist"
          icon="account"
        />
      )
    }
    if (this.state.error === false) {
      const { statement, dateStart, dateEnd, dobSSN, emailAddresses } =
        this.state

      return (
        <Template
          onSubmit={this.fetchStatement}
          canSubmit={this.canSubmit}
          statement={statement}
          dateStart={dateStart}
          dobSSN={dobSSN}
          dateEnd={dateEnd}
          emailAddresses={emailAddresses}
          onChange={this.handleChange}
        />
      )
    }
    return null
  }
}

export default withRouter(connect()(Statement))
