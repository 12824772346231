// @flow
import formatters from 'lib/formatters'
import React from 'react'
import type { Contact, Ticket } from 'lib/types'
import ticketFlag from 'styles/img/ticket.png'
import styles from './Header.css'
import { Link } from 'react-router-dom'

type Props = {
  balance: number,
  checking_balance: number,
  beneficiary: Contact,
  dod: Date,
  number: string,
  open_tickets: [Ticket],
  related_account_numbers: [string],
  status: string,
}

const renderOtherTrusts = (related_account_numbers) => {
  if (related_account_numbers.length > 0) {
    return (
      <div>
        <p className={styles.trustsItem}>Other Trusts:</p>
        {related_account_numbers.map(renderAccountLink)}
      </div>
    )
  } else {
    return <div></div>
  }
}

const renderClosingHeader = (status) => {
  let component = <div></div>

  if (status == 'closed') {
    component = <h5 className={styles.closedStatus}>{status.toUpperCase()}</h5>
  } else if (status == 'closing') {
    component = <h5 className={styles.closingStatus}>{status.toUpperCase()}</h5>
  } else if (status == 'suspended') {
    component = <h5 className={styles.closingStatus}>{status.toUpperCase()}</h5>
  }

  return component
}

const renderDeceasedHeader = (dod) => {
  if (dod) {
    return <h5 className={styles.deceasedFlag}>DECEASED</h5>
  } else {
    return <div></div>
  }
}

const renderAccountLink = (number, index) => {
  return (
    <Link to={`/accounts/${number}`} key={index} className={styles.trustsItem}>
      {number}
    </Link>
  )
}

const renderOtherBalances = (balance, checking_balance, totalBalanceLabel) => {
  if (balance == checking_balance) {
    return <div></div>
  } else {
    return (
      <div className={styles.headerLight}>
        <h5>{totalBalanceLabel}</h5>
        <h4>{formatters.money(balance || 0)}</h4>
      </div>
    )
  }
}

const Header = (props: Props) => {
  const {
    balance = 0.0,
    beneficiary = {},
    checking_balance = 0.0,
    number,
    open_tickets = [],
    related_account_numbers = [],
    status = '',
    sub_accounts=[],
  } = props

  const openTicketIcon =
    open_tickets.length > 0 ? (
      <div className={styles.ticketFlag}>
        <img src={ticketFlag} />
      </div>
    ) : (
      <div className={styles.ticketFlag} />
    )

  const standardHeight = balance == checking_balance ? '' : styles.standardHeight

  const totalBalanceLabel = sub_accounts.some(sub_account => sub_account.subaccount_type === 'msa')
    ? 'Total Balance (note MSA)'
    : 'Total Balance'

  return (
    <div className={styles.titleBox}>
      <div className={`${styles.nameWrapper} ${standardHeight}`}>
        <span className={styles.titleName}>
          {openTicketIcon}
          <div className={styles.trusts}>
            <h5 className={styles.accountNumber}>{number}</h5>
            {renderClosingHeader(status, number)}
            {renderOtherTrusts(related_account_numbers)}
          </div>
        </span>
        <div className={styles.accountNumberLine}>
          <h1>
            <Link to={`/beneficiaries/show/${beneficiary.id}`} className={styles.beneName}>
              {beneficiary.name}
            </Link>
          </h1>
          {renderDeceasedHeader(beneficiary.dod)}
        </div>
      </div>
      <div className={`${styles.balanceWrapper} ${standardHeight}`}>
        <div>
          <h5 className={styles.boxLabel}>Checking Balance</h5>
          <h2>{formatters.money(checking_balance || 0)}</h2>
          {renderOtherBalances(balance, checking_balance, totalBalanceLabel)}
        </div>
      </div>
    </div>
  )
}

export default Header
